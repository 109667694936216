<template>
  <div class="notification-container">
    <Notification
      v-for="({ duration, id, message }) of displaying"
      :key="id"
      :animationDuration="animationDuration"
      :duration="duration"
      :message="message"
      @close="dismiss(id)"
    />
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";
import { mapGetters } from "vuex";
import Notification from "@/components/notification.vue";

const ANIMATION_DURATION = 1500;
const MAX_DISPLAY = 3;

export default {
  components: {
    Notification,
  },
  data() {
    return {
      animationDuration: ANIMATION_DURATION,
      displaying: [],
      intervalId: null,
      queue: [],
    };
  },
  computed: {
    ...mapGetters(["jwtData", "jwtToken"]),
  },
  watch: {
    queue: {
      deep: true,
      handler(array) {
        if (array.length > 0 && !this.intervalId) {
          this.intervalId = setInterval(() => {
            if (this.displaying.length < MAX_DISPLAY) {
              const item = this.queue.shift();
              this.displaying.unshift(item);
            }
          }, 500);
        } else if (array.length === 0 && this.intervalId) {
          clearInterval(this.intervalId);
          this.intervalId = null;
        }
      },
    },
  },
  created() {
    this.$socket.connect(this.jwtToken);
  },
  mounted() {
    const { usuarioId } = this.jwtData.data;
    this.$socket
      .channel(`App.User.${usuarioId}`)
      .listen("Notification", (event) => {
        this.queue.push({
          id: uuid(),
          // duration: 3000,
          duration: 1000 * 15,  // 15s
          message: event.message,
        });
      });
  },
  methods: {
    dismiss(id) {
      const index = this.displaying.findIndex((item) => item.id === id);
      this.displaying.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-container {
  position: absolute;
  right: 0;
  top: 95px;  // Para compensar a altura do cabeçalho das páginas.
  z-index: 999;
}
</style>
